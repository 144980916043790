
import { defineComponent } from '@vue/composition-api'
import { VBtn } from 'vuetify/lib'

export default defineComponent({
  name: 'PagesBtn',

  extends: VBtn,

  props: {
    color: {
      type: String,
      default: 'success',
    },
    depressed: {
      type: Boolean,
      default: false,
    },
    default: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
